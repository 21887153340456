import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'
import { PartsStyleFunction, mode } from '@chakra-ui/theme-tools'

const baseStyle: PartsStyleFunction = (props) => {
  return {
    card: {
      display: 'grid',
      alignItems: 'start',
      bg: mode('white', 'gray.800')(props),
    },
    title: {
      display: 'flex',
      gap: 4,
      alignItems: 'center',
      justifyItems: 'start',
      py: {
        base: 2,
        xl: 4,
      },
      px: 4,
      bg: 'primary.700',
      borderTopRadius: 'md',
      color: 'white',
      fontWeight: 'medium',
      button: {
        display: 'flex',
        appearance: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        position: 'relative',
        whiteSpace: 'break-spaces',
        verticalAlign: 'middle',
        outline: 'transparent solid 2px',
        outlineOffset: '2px',
        lineHeight: 'var(--ok-lineHeights-button)',
        borderRadius: 'var(--ok-radii-md)',
        fontWeight: 'var(--ok-fontWeights-normal)',
        transitionProperty: 'var(--ok-transition-property-common)',
        transitionDuration: 'var(--ok-transition-duration-normal)',
        gap: 'var(--ok-space-1)',
        textAlign: 'start',
        height: 'auto',
        fontSize: 'var(--ok-fontSizes-xs)',
        paddingInlineStart: 'var(--ok-space-2)',
        paddingInlineEnd: 'var(--ok-space-2)',
        paddingTop: 'calc(var(--ok-sizes-1) - 1px)',
        paddingBottom: 'calc(var(--ok-sizes-1) - 1px)',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'var(--ok-colors-gray-400)',
        color: 'var(--ok-colors-primary-500)',
        background: 'var(--ok-colors-white)',
      }
    },
    body: {
      p: {
        base: 4,
        lg: 8,
      },
      gap: {
        base: 2,
        lg: 4,
      },
    },
  }
}

export const Card: ComponentMultiStyleConfig = {
  parts: ['card', 'title', 'body'],
  baseStyle,
  variants: {
    default: {
      card: {
        borderRadius: 'md',
        boxShadow: 'card',
      },
      title: {},
      body: {},
    },
  },
  defaultProps: {
    variant: 'default',
  },
}
