import {
  Box,
  BoxProps, Button,
  StylesProvider,
  SystemStyleObject,
  useMultiStyleConfig,
  useStyles,
} from '@chakra-ui/react'

export interface CardProps extends BoxProps {
  children: React.ReactNode
  variant?: 'default'
  colorScheme?: string
  sx?: SystemStyleObject
}

interface CardTitleProps extends BoxProps {
  children: React.ReactNode
}

interface CardBodyProps extends BoxProps {
  children: React.ReactNode
}

interface CardTitleButtonProps extends BoxProps {
  children: React.ReactNode
}

export const Card = (props: CardProps) => {
  const { variant, children, ...rest } = props

  const styles = useMultiStyleConfig('Card', { variant })

  return (
    <Box __css={styles.card} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  )
}

export const CardTitle = (props: CardTitleProps) => {
  const styles = useStyles()

  return <Box as="header" __css={styles.title} {...props} />
}

export const CardBody = (props: CardBodyProps) => {
  const styles = useStyles()

  return <Box as="main" __css={styles.body} {...props} />
}

export const CardTitleButton = ({children, ...props}: CardTitleButtonProps) => {
  return <Button>{children}</Button>;
}