import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import queryString from 'query-string'

export const useGetProductionGraphFilters = (params?: any) => {
  const query = queryString.stringify(params, { skipEmptyString: true })
  return useQuery(
    [ApiRouteEnum.PRODUCTION_GRAPH_FILTER, query],
    () => api.get<any>(`${ApiRouteEnum.PRODUCTION_GRAPH_FILTER}?${query}`),
    {
      retry: false,
    }
  )
}

