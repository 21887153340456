import api from '../api'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import queryString from 'query-string'

const getCzechMonthName = (date: string): string => {
  const months = [
    "Leden", "Únor", "Březen", "Duben", "Květen", "Červen",
    "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"
  ];
  const [, month] = date.split('-');
  return months[parseInt(month, 10) - 1];
};

export const useGetProductionGraph = (params?: any) => {
  const query = queryString.stringify(params, { skipEmptyString: true })
  return useQuery(
    [ApiRouteEnum.PRODUCTION_GRAPH_DATA, query],
    () => api.get<{name: string, value: number, monthName: string}[]>(`${ApiRouteEnum.PRODUCTION_GRAPH_DATA}?${query}`),
    {
      retry: false,
      select: (response) => {
        return {
          ...response,
          data: Object.values(response?.data).map((item) => ({
            ...item,
            // @ts-ignore
            name: getCzechMonthName(item.label) + " " + item.label.split('-')[0],
          })),
        }
      },
    }
  )
}

