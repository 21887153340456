export enum ApiRouteEnum {
  ORGANIZATION = '/organization',
  ORGANIZATION_EXPORT = '/organization/export',
  LOGIN = '/auth',
  USER = '/user',
  USER_ROLE = '/user/role',
  USER_TYPE = '/user/type',
  USER_CURRENT = '/user/current',
  USER_EXPORT = '/user/export',
  USER_DEGREES = '/getDegrees',
  NEWS = '/news',
  COMPANY = '/company',
  FAQ = '/help',
  HELP_DESK = '/help-desk',
  OFFERS = '/offer',
  HELP_DESK_QUESTION_TYPES = '/help-desk/question-types',
  CONTRACT = '/contract',
  ADDRESS = '/address',
  OFFER_FIELDS = '/offer/fields',
  OFFER_OTHER_FIELDS = '/offer/cz-nace',
  AUTH_OKK_SSO = '/auth/okk-sso',
  AUTH_OKH_SSO = '/auth/okh-sso',
  ARES = '/ares',
  EMAIL_TEMPLATE = '/email-template',
  PRODUCTION_GRAPH_DATA = '/graph/data',
  PRODUCTION_GRAPH_FILTER = '/graph/filterOptions',
}
