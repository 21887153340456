import {
  Alert,
  Box,
  Button,
  FormErrorMessage,
  Grid,
  Input,
  InputGroup as ChakraInputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'

import {
  FormSection,
  FormSectionBody,
  FormSectionDescription,
  FormSectionHeader,
  FormSectionTitle,
} from '../../components/FormSection'
import { MainContent } from '../../components/MainContent'
import { Add, Calendar, Check, Duplicate } from '../../icons'
import { FormButtons } from '../FormButtons'
import { SmeBusinessAreaSelector } from './SmeBusinessAreaSelector'
import {
  IAdditional,
  IApplicant,
  IBusiness,
  InsuranceValues,
  IPlace,
} from '../../data/InsuranceForm'
import { Form, Formik } from 'formik'
import { InputGroup } from '../../components/InputGroup'
import { FormLabel } from '../../components/FormLabel'
import { FormSpacer } from '../../components/FormSpacer'
import { InsuranceType } from './InsuranceType'
import * as Yup from 'yup'
import { RadioStack } from '../../components/RadioStack'
import { FormGroup } from '../../components/FormGroup'
import { InsuranceCompanies } from '../../components/InsuranceCompanies'
import { useGetCompanies } from '../../model/api/company/useGetCompany'
import { Loading } from '../../components/Loading'
import { AdditionalInsureeSmeInfo } from './AdditionalInsureeSmeInfo'
import { usePostStep1 } from '../../model/api/insuranceForm/usePostStep1'
import { ConfirmRemoveButton } from '../../components/ConfirmRemoveButton'
import { Step1FormSchema } from '../../model/yupSchemas/Step1FormSchema'
import AddressAutocomplete from '../../components/AddressAutocomplete'
import { IAddressSuggestion } from '../../model/interfaces/entities/IAddressSuggestion'
import {
  transformAddressToStreet,
  transformAddressToStreetNumber,
} from '../../model/utils/addressUtils'
import { useNavigate, useParams } from 'react-router-dom'
import { usePatchStep1 } from '../../model/api/insuranceForm/usePatchStep1'
import { InsuranceTypeEnum } from '../../model/enums/InsuranceTypeEnum'
import { initialPlaceValues as constantInitialPlaceValues } from '../../model/constants/insuranceForm'
import { validateAndScroll } from '../../model/utils/formHelpers'
import React, { useContext, useEffect, useState } from 'react'
import { NumberInputDecorator } from '../../model/utils/numberInputUtils'
import { ConstructionTypeEnum } from '../../model/enums/ConstructionTypeEnum'
import _, { omit } from 'lodash'
import { LIABILITY_OPTIONS } from '../../model/utils/insuranceLiabilityUtils'
import { FormControl } from '../../components/FormControl'
import { FormIsDisabledContext } from '../../model/contexts/FormIsDisabledContext'
import { DefaultErrorCallback } from '../../model/api/DefaultErrorCallback'
import { IOffersItem } from '../../model/interfaces/entities/IOffers'
import { useCustomCopyOffer } from '../../model/hooks/useCustomCopyOffer'
import { RequiredField } from '../RequiredField'
import { ICompanyV2 } from '../../model/interfaces/entities/ICompany'
import useCloneClickHandler from '../../model/hooks/useCloneClickHandler'
import { Datepicker } from '../../components/Datepicker'
import moment from 'moment/moment'
import { compareObjects } from '../../utils/object'

interface Step1FormProps {
  type: 'edit'|'new'
  onSubmit: Function
  initialBusinessValues: IBusiness
  initialInsuranceValues: InsuranceValues
  initialPlaceValues: IPlace[]
  initialAdditionalValues: IAdditional
  initialApplicantValues: IApplicant
  viewOnly?: boolean
  cloneAvailable?: boolean
}

export const Step1Form = ({
  type,
  onSubmit,
  initialBusinessValues,
  initialInsuranceValues,
  initialPlaceValues,
  initialAdditionalValues,
  initialApplicantValues,
  viewOnly,
  cloneAvailable = false,
}: Step1FormProps) => {
  const { data: dataCompanies, isLoading: isLoadingCompanies } = useGetCompanies()
  const { id } = useParams<'id'>()
  const navigate = useNavigate()
  const initialValues: Yup.InferType<typeof Step1FormSchema> = {
    business: initialBusinessValues,
    insurance: {...initialInsuranceValues},
    places: initialPlaceValues || [{ ...constantInitialPlaceValues }],
    additional: initialAdditionalValues,
    applicant: initialApplicantValues,
  }

  const [originalInsuranceDateStart, setOriginalInsuranceDateStart] = useState(initialValues.insurance.dateStart);

  const formIsDisabled = useContext(FormIsDisabledContext)

  if (!formIsDisabled && moment(initialValues.insurance.dateStart) < moment(moment().format('YYYY-MM-DD')).add(1, 'day')) {
    initialValues.insurance.dateStart = '';
  }

  const { mutateCopyOffer, isCopyingOffer } = useCustomCopyOffer(id)
  const handleCloneClick = useCloneClickHandler(mutateCopyOffer)

  const toastMessage = useToast()

  const successCallBack: (response: IOffersItem) => void = (response) => {
    onSubmit(response)
  }

  const { mutate, isLoading } = usePostStep1(successCallBack, DefaultErrorCallback)
  const { mutate: patchMutate, isLoading: patchIsLoading } = usePatchStep1(
    successCallBack,
    DefaultErrorCallback,
    id,
  )
  const onSubmitForm = (values: Yup.InferType<typeof Step1FormSchema>) => {
    if (
      values?.insurance?.type?.includes(InsuranceTypeEnum.BUILDING) &&
      values?.insurance?.type?.length === 1
    ) {
      values.additional = {
        supplyValue: 0,
        machineValue: 0,
        totalReceipt: 0,
        numberEmployee: 0,
        liabilityLimit: 0,
        region: 'cr',
      }
    }

    if (!values?.insurance?.type?.includes(InsuranceTypeEnum.BUILDING)) {
      values.places = values?.places?.map((p) => {
        return omit(p, [
          'constructionType',
          'floorQuantity',
          'area',
          'buildingPrice',
          'technicalState',
        ])
      }) as any
    } else {
      values.places = values?.places?.map((p) => ({
        ...p,
        floorQuantity: Number(p.floorQuantity),
      }))
    }

    if (values?.insurance?.type?.includes(InsuranceTypeEnum.RESPONSIBILITY)) {
      values.additional.liabilityLimit = Number(values.additional.liabilityLimit)
    } else {
      delete values.additional.liabilityLimit
    }

    if (values?.insurance?.type?.includes(InsuranceTypeEnum.MACHINE)) {
      values.additional.supplyValue = Number(values.additional.supplyValue)
    }

    if (
      values?.applicant?.addressSameAsPlace === true &&
      values.places &&
      values.places.length > 0
    ) {
      const { street, streetNumber, city, zip, ruianId } = values.places[0]
      values.applicant = {
        ...values.applicant,
        street,
        streetNumber,
        city,
        zip,
        ruianId: ruianId!,
      }
    }

    const emptyPlaceIndexes: number[] = []
    values.places?.forEach((place, index) => {
      if (!place.street || !place.streetNumber || !place.city || !place.zip) {
        emptyPlaceIndexes.push(index + 1)
      }
    })
    emptyPlaceIndexes.forEach((place) => {
      delete values.places![place]
    })

    // Clear fields moved to Step 4
    delete (values.applicant as any).firstName
    delete (values.applicant as any).familyName
    delete (values.applicant as any).identificationNumber

    id && document.URL.includes('edit') ? patchMutate(values) : mutate(values)
  }

  const [validatesCount, setValidatesCount] = useState(0);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validationSchema={Step1FormSchema}
      enableReinitialize={false}
    >
      {({ errors, touched, values, handleChange, setValues, validateForm, setFieldValue }) => {
        const showAdditionalInsuranceInfoForm = (() => {
          if (!values.insurance.type) {
            return false
          }
          if (values.insurance.type.length === 0) {
            return false
          }
          if (
            values.insurance.type.length === 1 &&
            values.insurance.type.includes(InsuranceTypeEnum.BUILDING)
          ) {
            return false
          }
          return true
        })()

        const selectedCompaniesTexts: string[] = (() => {
          const selectedCompanyIds = values.insurance?.companies || []
          const texts: string[] = []

          dataCompanies?.data?.forEach((c: ICompanyV2) => {
            if (selectedCompanyIds.includes(c.id) && !!c.infoTextK1 && c.infoTextK1 !== '') {
              texts.push(c.infoTextK1)
            }
          })

          return texts
        })()

        const changes = compareObjects(initialValues, values)
          .filter((diff: any) => !['applicant.addressSameAsPlace'].includes(diff.path));

        // @ts-ignore
        const canNextWithoutSave = changes.length === 0 && type === 'edit';

        const hasBigRiskField = () => {
          if (values.applicant.hasBigRisk) {
            return true;
          }

          if (values.insurance.companies !== undefined && dataCompanies) {
            const hasBigRiskFields = values.insurance.companies.filter((companyId: number) => {
              const company = dataCompanies.data.find((companyData) => companyData.id === companyId);
              return company && company.hasBigRiskField;
            }).length;

            return hasBigRiskFields > 0;
          }

          return false;
        }

        return (
          <Form>
            <MainContent>
              <FormSection>
                <FormSectionHeader>
                  <FormSectionTitle>
                    Oblast podnikání
                    <RequiredField />
                  </FormSectionTitle>

                  <FormSectionDescription>
                    Vyberte oblast a konkrétní obor podnikání klienta.
                    <Spacer mt={3} />
                    Seznam nepojistitelných činností naleznete
                    <Link
                      href="https://webs.okholding.cz/down_public/businesska/Nepojistitelne_cinnosti.pdf"
                      isExternal
                    >
                      &nbsp;zde.
                    </Link>
                  </FormSectionDescription>
                </FormSectionHeader>

                <FormSectionBody sx={{ w: '100%' }}>
                  <SmeBusinessAreaSelector
                    values={values.business}
                    setValues={(business: IBusiness) => setValues({ ...values, business })}
                    touched={touched}
                    errors={errors}
                  />
                </FormSectionBody>
              </FormSection>

              <FormSection>
                <FormSectionHeader>
                  <FormSectionTitle>Parametry pojištění</FormSectionTitle>

                  <FormSectionDescription>
                    Zadejte základní parametry pojištění.
                  </FormSectionDescription>
                </FormSectionHeader>

                <FormSectionBody>
                  {/* Project name */}
                  <FormControl
                    id="insurance.name"
                    isInvalid={touched.insurance?.name && !!errors.insurance?.name}
                  >
                    <FormLabel htmlFor="insurance.name">Název nabídky</FormLabel>
                    <InputGroup
                      id="insurance.name"
                      placeholder="Zadejte název nabídky"
                      htmlSize={32}
                      value={values.insurance.name}
                      onChange={handleChange}
                    />
                    {touched.insurance?.name && (
                      <FormErrorMessage>{errors.insurance?.name}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormSpacer />

                  <InsuranceType
                    touched={touched}
                    errors={errors}
                    values={values}
                    setValues={setValues}
                  />

                  <FormSpacer />

                  <FormControl
                    id="insurance.range"
                    isInvalid={touched.insurance?.range && !!errors.insurance?.range}
                  >
                    <FormLabel>Rozsah pojištění</FormLabel>
                    <RadioGroup
                      id="insurance.range"
                      name="insurance.range"
                      value={values?.insurance.range}
                    >
                      <RadioStack variant="stackedTile">
                        <Radio onChange={handleChange} value="low">
                          Nižší
                        </Radio>
                        <Radio onChange={handleChange} value="medium">
                          Střední
                        </Radio>
                        <Radio onChange={handleChange} value="high">
                          Vyšší
                        </Radio>
                      </RadioStack>
                    </RadioGroup>

                    {touched.insurance?.range && (
                      <FormErrorMessage>{errors.insurance?.range}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormSpacer />

                  <FormGroup>
                    {/* Date */}
                    <FormControl
                      id="insurance.dateStart"
                      isInvalid={touched.insurance?.dateStart && !!errors.insurance?.dateStart}
                    >
                      <FormLabel htmlFor="insurance.dateStart">Datum počátku pojištění</FormLabel>

                      <Datepicker
                        id="insurance.dateStart"
                        name="insurance.dateStart"
                        date={moment(values?.insurance.dateStart).toDate()}
                        onChange={handleChange}
                        onDateChange={(date: any) => setFieldValue('insurance.dateStart', moment(date).format('YYYY-MM-DD'))}
                        options={{
                          ...(formIsDisabled ? {} : {minDate: moment().add(1, 'day').format('YYYY-MM-DD')})
                        }}
                      />
                      {touched.insurance?.dateStart && (
                        <>
                          {originalInsuranceDateStart === initialValues?.insurance.dateStart && (
                            <FormErrorMessage>{errors.insurance?.dateStart}</FormErrorMessage>
                          )}
                          {originalInsuranceDateStart !== initialValues?.insurance.dateStart && (
                            <FormErrorMessage>Původní datum počátku pojištění je v minulosti "{moment(originalInsuranceDateStart).format('DD.MM.YYYY')}". Pro pokračování je potřeba zadat nové.</FormErrorMessage>
                          )}
                        </>
                      )}
                    </FormControl>
                  </FormGroup>

                  <FormSpacer />

                  <FormGroup size="lg">
                    <FormControl
                      id="insurance.paymentFrequency"
                      isInvalid={
                        touched.insurance?.paymentFrequency && !!errors.insurance?.paymentFrequency
                      }
                    >
                      <FormLabel htmlFor="paymentFrequency">Frekvence plateb</FormLabel>

                      <RadioGroup
                        name="insurance.paymentFrequency"
                        value={values.insurance.paymentFrequency}
                      >
                        <Stack direction="column" spacing={4}>
                          <Radio onChange={handleChange} value="year">
                            Roční
                          </Radio>
                          <Radio onChange={handleChange} value="half_year">
                            Pololetní
                          </Radio>
                          <Radio onChange={handleChange} value="quarter">
                            Čtvrtletní
                          </Radio>
                        </Stack>
                      </RadioGroup>
                      {touched.insurance?.paymentFrequency && (
                        <FormErrorMessage>{errors.insurance?.paymentFrequency}</FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl
                      id="insurance.paymentMethod"
                      isInvalid={
                        touched.insurance?.paymentMethod && !!errors.insurance?.paymentMethod
                      }
                    >
                      <FormLabel htmlFor="insurance.paymentMethod">Způsob platby</FormLabel>

                      <RadioGroup
                        name="insurance.paymentMethod"
                        value={values.insurance.paymentMethod}
                      >
                        <Stack direction="column" spacing={4}>
                          <Radio onChange={handleChange} value="bank_account">
                            Převodem z účtu
                          </Radio>
                        </Stack>
                      </RadioGroup>
                      {touched.insurance?.paymentMethod && (
                        <FormErrorMessage>{errors.insurance?.paymentMethod}</FormErrorMessage>
                      )}
                    </FormControl>
                  </FormGroup>

                  <FormSpacer />

                  {isLoadingCompanies || !dataCompanies ? (
                    <Loading />
                  ) : (
                    <FormControl
                      id="insurance.companies"
                      isInvalid={touched.insurance?.companies && !!errors.insurance?.companies}
                    >
                      <InsuranceCompanies
                        values={values.insurance}
                        setFinalCompanies={(newInsuranceValues: InsuranceValues) =>
                          setValues({ ...values, insurance: newInsuranceValues })
                        }
                        companies={dataCompanies.data}
                        selectAllOnMount={typeof id === 'undefined'}
                      />
                      {touched.insurance?.companies && (
                        <FormErrorMessage>{errors.insurance?.companies}</FormErrorMessage>
                      )}
                    </FormControl>
                  )}
                  {selectedCompaniesTexts.length > 0 && (
                    <Alert
                      textColor="orange.900"
                      bgColor="orange.100"
                      flexDir="column"
                      justifyContent="start"
                      alignItems="start"
                      maxW="3xl"
                      borderColor="orange.200"
                    >
                      {selectedCompaniesTexts.map((text: string, index: number) => (
                        <Text marginTop={2} marginBottom={2} key={index}>
                          {text}
                        </Text>
                      ))}
                    </Alert>
                  )}
                </FormSectionBody>
              </FormSection>

              <FormSection>
                <FormSectionHeader>
                  <FormSectionTitle>Místo pojištění</FormSectionTitle>

                  <FormSectionDescription>
                    Zadejte základní údaje o nemovitosti. Maximální hodnota všech pojištěných
                    nemovitostí - ČPP 15 mil. Kč (maximální hodnota veškerého pojištěného majetku),
                    Slavia 50 mil. Kč, Direct 50 mil. Kč. Maximální počet míst pojištění - ČPP 3
                    místa, Slavia 5míst, Direct - 3 místa.
                  </FormSectionDescription>
                </FormSectionHeader>

                <FormSectionBody>
                  {values.places?.map((address, index) => (
                    <div key={index}>
                      <Box sx={{ display: 'grid', gap: 8, justifyItems: 'start' }}>
                        <Grid
                          sx={{
                            gap: 4,
                            p: 4,
                            justifyItems: 'start',
                            bg: 'gray.100',
                            borderRadius: 'md',
                          }}
                        >
                          {index > 0 && (
                            <ConfirmRemoveButton
                              key={Math.random()}
                              onClick={() => {
                                const newArray = values.places!.filter(
                                  (e, eIndex) => eIndex !== index,
                                )
                                setValues({ ...values, places: newArray })
                              }}
                            />
                          )}
                          <AddressAutocomplete
                            onAddressAccepted={(address: IAddressSuggestion) => {
                              const newArr = values.places ? [...values.places] : []
                              newArr[index].street = transformAddressToStreet(address)
                              newArr[index].zip = address.postalCode || ''
                              newArr[index].city = address.city || ''
                              newArr[index].streetNumber = transformAddressToStreetNumber(address)
                              newArr[index].ruianId = address.ruianId
                              setValues({ ...values, places: newArr })
                            }}
                          />
                          <FormGroup>
                            <FormControl
                              id={`place.${index}.street`}
                              //@ts-ignore
                              isInvalid={
                                touched.places && //@ts-ignore
                                touched.places[index]?.street && //@ts-ignore
                                errors.places //@ts-ignore
                                  ? !!errors.places[index]?.street
                                  : ''
                              }
                            >
                              <FormLabel>Ulice</FormLabel>
                              <InputGroup
                                htmlSize={32}
                                value={values.places![index].street}
                                placeholder="Např. Hlavní"
                                isDisabled={true}
                              />
                              {/*//@ts-ignore*/}
                              {touched.places && //@ts-ignore
                                touched.places[index]?.street && ( //@ts-ignore
                                  <FormErrorMessage>
                                    {errors.places //@ts-ignore
                                      ? errors.places[index]?.street
                                      : ''}
                                  </FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl
                              id={`place.${index}.streetNumber`} //@ts-ignore
                              isInvalid={
                                touched.places && //@ts-ignore
                                touched.places[index]?.streetNumber && //@ts-ignore
                                errors.places //@ts-ignore
                                  ? !!errors.places[index]?.streetNumber
                                  : ''
                              }
                            >
                              <FormLabel>Číslo popisné nebo orientační</FormLabel>

                              <InputGroup
                                htmlSize={8}
                                placeholder="Např. 1234"
                                value={values.places![index].streetNumber}
                                isDisabled={true}
                              />
                              {/*//@ts-ignore*/}
                              {touched.places && //@ts-ignore
                                touched.places[index]?.street && ( //@ts-ignore
                                  <FormErrorMessage>
                                    {errors.places //@ts-ignore
                                      ? errors.places[index]?.street
                                      : ''}
                                  </FormErrorMessage>
                                )}
                            </FormControl>
                          </FormGroup>

                          <FormGroup>
                            <FormControl
                              id={`place.${index}.zip`} //@ts-ignore
                              isInvalid={
                                touched.places && //@ts-ignore
                                touched.places[index]?.zip && //@ts-ignore
                                errors.places //@ts-ignore
                                  ? !!errors.places[index]?.zip
                                  : ''
                              }
                            >
                              <FormLabel>PSČ</FormLabel>

                              <InputGroup
                                htmlSize={6}
                                placeholder="Např. 110 00"
                                value={values.places![index]?.zip}
                                isDisabled={true}
                              />
                              {/*//@ts-ignore*/}
                              {touched.places && //@ts-ignore
                                touched.places[index]?.zip && ( //@ts-ignore
                                  <FormErrorMessage>
                                    {errors.places //@ts-ignore
                                      ? errors.places[index]?.zip
                                      : ''}
                                  </FormErrorMessage>
                                )}
                            </FormControl>

                            <FormControl
                              id={`place.${index}.city`} //@ts-ignore
                              isInvalid={
                                touched.places && //@ts-ignore
                                touched.places[index]?.city && //@ts-ignore
                                errors.places //@ts-ignore
                                  ? !!errors.places[index]?.city
                                  : ''
                              }
                            >
                              <FormLabel>Město</FormLabel>

                              <InputGroup
                                htmlSize={6}
                                placeholder="Prague"
                                value={values.places![index]?.city}
                                isDisabled={true}
                              />
                              {/*//@ts-ignore*/}
                              {touched.places && //@ts-ignore
                                touched.places[index]?.city && ( //@ts-ignore
                                  <FormErrorMessage>
                                    {errors.places //@ts-ignore
                                      ? errors.places[index]?.city
                                      : ''}
                                  </FormErrorMessage>
                                )}
                            </FormControl>
                          </FormGroup>

                          {(values.insurance.type || []).includes(InsuranceTypeEnum.BUILDING) && (
                            <>
                              <FormControl
                                id={`place.${index}.constructionType`} //@ts-ignore
                                isInvalid={
                                  //@ts-ignore
                                  touched.places && //@ts-ignore
                                  touched.places[index]?.constructionType && //@ts-ignore
                                  errors.places //@ts-ignore
                                    ? !!errors.places[index]?.constructionType
                                    : ''
                                }
                              >
                                <FormLabel>
                                  Typ konstrukce
                                  <RequiredField />
                                </FormLabel>

                                <Select
                                  name={`places[${index}].constructionType`}
                                  placeholder="Vyberte typ konstrukce"
                                  defaultValue={values.places![index]?.constructionType}
                                  onChange={handleChange}
                                >
                                  <option value={ConstructionTypeEnum.BRICK_BUILDING}>Zděná</option>
                                  <option value={ConstructionTypeEnum.CONCRETE_BUILDING}>
                                    Betonová
                                  </option>
                                  <option value={ConstructionTypeEnum.STEEL_BUILDING}>
                                    Ocelová
                                  </option>
                                </Select>
                                {/*//@ts-ignore*/}
                                {touched.places && //@ts-ignore
                                  touched.places[index]?.constructionType && ( //@ts-ignore
                                    <FormErrorMessage>
                                      {errors.places //@ts-ignore
                                        ? errors.places[index]?.constructionType
                                        : ''}
                                    </FormErrorMessage>
                                  )}
                              </FormControl>

                              <FormControl
                                id={`place.${index}.floorQuantity`} //@ts-ignore
                                isInvalid={
                                  //@ts-ignore
                                  touched.places && //@ts-ignore
                                  touched.places[index]?.floorQuantity && //@ts-ignore
                                  errors.places //@ts-ignore
                                    ? !!errors.places[index]?.floorQuantity
                                    : ''
                                }
                              >
                                <FormLabel tooltip="Budovy vyšší než přízemí + 5 pater nelze pojistit.">
                                  Počet nadzemních podlaží
                                </FormLabel>

                                <Select
                                  name={`places[${index}].floorQuantity`}
                                  defaultValue="floor"
                                  value={values.places![index]?.floorQuantity}
                                  onChange={handleChange}
                                  onClick={() => {
                                    console.log(values.places![index]?.floorQuantity)
                                  }}
                                >
                                  <option value={0}>přízemí</option>
                                  <option value={1}>přízemí + 1 patro</option>
                                  <option value={2}>přízemí + 2 patra</option>
                                  <option value={3}>přízemí + 3 patra</option>
                                  <option value={4}>přízemí + 4 patra</option>
                                  <option value={5}>přízemí + 5 pater</option>
                                </Select>
                                {/*//@ts-ignore*/}
                                {touched.places && //@ts-ignore
                                  touched.places[index].floorQuantity && ( //@ts-ignore
                                    <FormErrorMessage>
                                      {errors.places //@ts-ignore
                                        ? errors.places[index]?.floorQuantity
                                        : ''}
                                    </FormErrorMessage>
                                  )}
                              </FormControl>

                              <FormControl
                                id={`places.${index}.area`} //@ts-ignore
                                isInvalid={
                                  //@ts-ignore
                                  touched.places && //@ts-ignore
                                  touched.places[index]?.area && //@ts-ignore
                                  errors.places //@ts-ignore
                                    ? !!errors.places[index]?.area
                                    : ''
                                }
                              >
                                <FormLabel tooltip="Plocha ohraničená pravoúhlými průměty vnějšího líce obvodových konstrukcí všech nadzemních i podzemních podlaží do vodorovné roviny. Je uvedena v katastru nemovitostí.">
                                  Zastavěná plocha
                                </FormLabel>

                                <ChakraInputGroup maxW={32}>
                                  <Input
                                    name={`places[${index}].area`}
                                    placeholder="Např. 128"
                                    value={NumberInputDecorator.denormalize(
                                      values.places![index]?.area,
                                    )}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `places[${index}].area`,
                                        NumberInputDecorator.normalizeFromEvent(e),
                                      )
                                    }
                                  />
                                  <InputRightElement children="㎡" />
                                </ChakraInputGroup>
                                {/*//@ts-ignore*/}
                                {touched.places && //@ts-ignore
                                  touched.places[index]?.area && ( //@ts-ignore
                                    <FormErrorMessage>
                                      {errors.places //@ts-ignore
                                        ? errors.places[index]?.area
                                        : ''}
                                    </FormErrorMessage>
                                  )}
                              </FormControl>

                              <FormControl
                                id={`places[${index}].buildingPrice`} //@ts-ignore
                                isInvalid={
                                  //@ts-ignore
                                  touched.places && //@ts-ignore
                                  touched.places[index]?.buildingPrice && //@ts-ignore
                                  errors.places //@ts-ignore
                                    ? !!errors.places[index]?.buildingPrice
                                    : ''
                                }
                              >
                                <FormLabel tooltip="Hodnota budovy">Hodnota budovy</FormLabel>

                                <ChakraInputGroup maxW={48}>
                                  <Input
                                    name={`places[${index}].buildingPrice`}
                                    placeholder="Např: 50 000 000"
                                    value={NumberInputDecorator.denormalize(
                                      values.places![index]?.buildingPrice,
                                    )}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `places[${index}].buildingPrice`,
                                        NumberInputDecorator.normalizeFromEvent(e),
                                      )
                                    }
                                  />
                                  <InputRightElement children="Kč" />
                                </ChakraInputGroup>
                                {/*//@ts-ignore*/}
                                {touched.places && //@ts-ignore
                                  touched.places[index]?.buildingPrice && ( //@ts-ignore
                                    <FormErrorMessage>
                                      {errors.places //@ts-ignore
                                        ? errors.places[index]?.buildingPrice
                                        : ''}
                                    </FormErrorMessage>
                                  )}
                              </FormControl>

                              <FormControl
                                id={`place.${index}.technicalState`} //@ts-ignore
                                isInvalid={
                                  //@ts-ignore
                                  touched.places && //@ts-ignore
                                  touched.places[index]?.technicalState && //@ts-ignore
                                  errors.places //@ts-ignore
                                    ? !!errors.places[index]?.technicalState
                                    : ''
                                }
                              >
                                <FormLabel tooltip="Budovy ve špatném technickém stavu není možné pojistit.">
                                  Dobrý technický stav
                                  <RequiredField />
                                </FormLabel>

                                <RadioGroup
                                  name={`places[${index}].technicalState`}
                                  value={values.places![index]?.technicalState}
                                >
                                  <RadioStack variant="stackedTile">
                                    <Radio onChange={handleChange} value="good">
                                      Ano
                                    </Radio>
                                    <Radio onChange={handleChange} value="bad">
                                      Ne
                                    </Radio>
                                  </RadioStack>
                                </RadioGroup>
                                {/*//@ts-ignore*/}
                                {touched.places && //@ts-ignore
                                  touched.places[index]?.technicalState && ( //@ts-ignore
                                    <FormErrorMessage>
                                      {errors.places //@ts-ignore
                                        ? errors.places[index]?.technicalState
                                        : ''}
                                    </FormErrorMessage>
                                  )}
                              </FormControl>
                            </>
                          )}
                        </Grid>
                      </Box>
                    </div>
                  ))}

                  {!formIsDisabled && (
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        const currentPlaces = values.places || []

                        setValues({
                          ...values,
                          places: [...currentPlaces, { ...constantInitialPlaceValues }],
                        })
                      }}
                    >
                      <Add color="secondary.500" />
                      Přidat další adresu
                    </Button>
                  )}
                </FormSectionBody>
              </FormSection>
              {showAdditionalInsuranceInfoForm && (
                <FormSection>
                  <FormSectionHeader>
                    <FormSectionTitle>Doplňující informace o pojištění</FormSectionTitle>

                    <FormSectionDescription>
                      Zadejte doplňující informace. Maximální hodnota pojištěných zásob a movitých
                      věcí - ČPP 15, mil. Kč (maximální hodnota veškerého majetku), Slavia 20 mil.
                      Kč, Direct 40 mil. Kč (zásoby max 20 mil. Kč, movité věci max 20 mil. Kč).
                    </FormSectionDescription>
                  </FormSectionHeader>

                  <FormSectionBody>
                    {values.insurance.type?.includes(InsuranceTypeEnum.MACHINE) && (
                      <FormControl
                        id="additional.machineValue"
                        isInvalid={
                          touched.additional?.machineValue && !!errors.additional?.machineValue
                        }
                      >
                        <FormLabel>Hodnota výrobního a provozního zařízení</FormLabel>

                        <ChakraInputGroup maxW={48}>
                          <Input
                            name="additional.machineValue"
                            placeholder="Např: 50 000 000"
                            value={NumberInputDecorator.denormalize(values.additional.machineValue)}
                            onChange={(e) =>
                              setFieldValue(
                                'additional.machineValue',
                                NumberInputDecorator.normalizeFromEvent(e),
                              )
                            }
                          />
                          <InputRightElement children="Kč" />
                        </ChakraInputGroup>
                        {touched.additional?.machineValue && (
                          <FormErrorMessage>{errors.additional?.machineValue}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}

                    {values.insurance.type?.includes(InsuranceTypeEnum.MACHINE) && (
                      <FormControl
                        id="additional.supplyValue"
                        isInvalid={
                          validatesCount > 0 && !!errors.additional?.supplyValue
                        }
                      >
                        <FormLabel>
                          Hodnota zásob
                          <RequiredField />
                        </FormLabel>

                        <ChakraInputGroup maxW={48}></ChakraInputGroup>
                        {!!errors.additional?.supplyValue && (
                          <FormErrorMessage>{errors.additional?.supplyValue}</FormErrorMessage>
                        )}

                        <Select
                          name="additional.supplyValue"
                          placeholder="Vyberte hodnotu"
                          isRequired={true}
                          value={values.additional.supplyValue}
                          onChange={(e) => setFieldValue('additional.supplyValue', e.target.value)}
                        >
                          <option value={0}>0 Kč</option>
                          <option value={250000}>250 000 Kč</option>
                          <option value={500000}>500 000 Kč</option>
                          <option value={1000000}>1 000 000 Kč</option>
                          <option value={3000000}>3 000 000 Kč</option>
                          <option value={5000000}>5 000 000 Kč</option>
                          <option value={10000000}>10 000 000 Kč</option>
                          <option value={15000000}>15 000 000 Kč</option>
                          <option value={20000000}>20 000 000 Kč</option>
                          <option value={25000000}>25 000 000 Kč</option>
                          <option value={30000000}>30 000 000 Kč</option>
                          <option value={40000000}>40 000 000 Kč</option>
                          <option value={50000000}>50 000 000 Kč</option>
                        </Select>
                      </FormControl>
                    )}

                    {values.insurance.type?.includes(InsuranceTypeEnum.RESPONSIBILITY) && (
                      <FormControl
                        id="additional.totalReceipt"
                        isInvalid={
                          touched.additional?.totalReceipt && !!errors.additional?.totalReceipt
                        }
                      >
                        <FormLabel tooltip="Zadejte celkový příjem za posledních 12 měsíců. U nově provozované činnosti uveďte předpokládaný příjem za 12 měsíců.">
                          Celkový příjem
                        </FormLabel>

                        <ChakraInputGroup maxW={48}>
                          <Input
                            name="additional.totalReceipt"
                            placeholder="Např: 50 000 000"
                            value={NumberInputDecorator.denormalize(values.additional.totalReceipt)}
                            onChange={(e) =>
                              setFieldValue(
                                'additional.totalReceipt',
                                NumberInputDecorator.normalizeFromEvent(e),
                              )
                            }
                          />
                          <InputRightElement children="Kč" />
                        </ChakraInputGroup>
                        {touched.additional?.totalReceipt && (
                          <FormErrorMessage>{errors.additional?.totalReceipt}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}

                    {(values.insurance.type?.includes(InsuranceTypeEnum.STOPPED_BUSINESS) ||
                      values.insurance.type?.includes(InsuranceTypeEnum.RESPONSIBILITY)) && (
                      <FormControl
                        id="additional.numberEmployee"
                        isInvalid={
                          touched.additional?.numberEmployee && !!errors.additional?.numberEmployee
                        }
                      >
                        <FormLabel>Počet zaměstnanců</FormLabel>

                        <ChakraInputGroup maxW={32}>
                          <Input
                            name="additional.numberEmployee"
                            placeholder="Např: 100"
                            value={NumberInputDecorator.denormalize(
                              values.additional.numberEmployee,
                            )}
                            onChange={(e) =>
                              setFieldValue(
                                'additional.numberEmployee',
                                NumberInputDecorator.normalizeFromEvent(e),
                              )
                            }
                          />
                        </ChakraInputGroup>
                        {touched.additional?.numberEmployee && (
                          <FormErrorMessage>{errors.additional?.numberEmployee}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                    {values.insurance.type?.includes(InsuranceTypeEnum.RESPONSIBILITY) && (
                      <>
                        <FormControl
                          id="additional.liabilityLimit"
                          isInvalid={
                            touched.additional?.liabilityLimit &&
                            !!errors.additional?.liabilityLimit
                          }
                        >
                          <FormLabel tooltip="Vyberte limit pojistného plnění">
                            Obecná odpovědnost za újmu
                            <RequiredField />
                          </FormLabel>

                          <ChakraInputGroup maxW={32}>
                            <Select
                              name="additional.liabilityLimit"
                              placeholder="Vyberte limit pojistného plnění"
                              value={values.additional.liabilityLimit}
                              onChange={handleChange}
                            >
                              {LIABILITY_OPTIONS.map((option) => (
                                <option value={option.value} key={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Select>
                          </ChakraInputGroup>
                          {touched.additional?.liabilityLimit && (
                            <FormErrorMessage>
                              {errors.additional?.liabilityLimit as any}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                        <Spacer />
                        <FormControl
                          id="additional.region"
                          isInvalid={touched.additional?.region && !!errors.additional?.region}
                        >
                          <FormLabel>
                            Územní platnosti
                            <RequiredField />
                          </FormLabel>
                          <Select
                            name="additional.region"
                            value={values?.additional.region}
                            placeholder="Vyberte územní platnost"
                            onChange={handleChange}
                          >
                            <option value="cr">Česká republika</option>
                            <option value="cr_around">
                              Česká republika a okolní (přilehlé) státy
                            </option>
                            <option value="eu">Evropa</option>
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </FormSectionBody>
                </FormSection>
              )}
              <FormSection>
                <FormSectionHeader>
                  <FormSectionTitle>Informace o pojistníkovi</FormSectionTitle>
                  <FormSectionDescription>Zadejte informace o pojistníkovi.</FormSectionDescription>
                </FormSectionHeader>

                <FormSectionBody>
                  <AdditionalInsureeSmeInfo
                    values={values.applicant}
                    setValues={(applicantValues: IApplicant) =>
                      setValues({ ...values, applicant: applicantValues })
                    }
                    touched={touched}
                    errors={errors}
                    extended={false}
                    showAddress={true}
                    showSkipAddress={true}
                    isAddressSameAsPlace={
                      typeof values.places !== 'undefined' &&
                      values.places.length > 0 &&
                      typeof values.applicant !== 'undefined' &&
                      values.applicant.ruianId === values.places[0].ruianId &&
                      !!values.places[0].ruianId &&
                      !!values.applicant.ruianId
                    }
                  />

                  {hasBigRiskField() && (
                    <>
                      <FormLabel htmlFor="paymentFrequency">Splňuje pojistník alespoň 2 ze 3 níže uvedených podmínek?</FormLabel>
                      <ul style={{marginLeft: '1.1rem'}}>
                        <li>Je čistý obrat min. 13 600 000 EUR (cca 340 000 000 Kč)?</li>
                        <li>Je úhrn rozvahy min. 6 600 000 EUR (cca 165 000 000 Kč)?</li>
                        <li>Je průměrný roční stav zaměstnanců min. 250?</li>
                      </ul>

                      <RadioGroup
                        id="insurance.range"
                        name="insurance.range"
                        value={values?.insurance.range}
                      >
                        <RadioStack variant="stackedTile">
                          <Radio onChange={handleChange} value="low">
                            Ano
                          </Radio>
                          <Radio onChange={handleChange} value="medium">
                            Ne
                          </Radio>
                        </RadioStack>
                      </RadioGroup>

                      {touched.insurance?.range && (
                        <FormErrorMessage>{errors.insurance?.range}</FormErrorMessage>
                      )}
                    </>
                  )}

                </FormSectionBody>
              </FormSection>

              <FormButtons
                leftButtons={
                  cloneAvailable && (
                    <Button
                      isLoading={isCopyingOffer}
                      variant="outline"
                      size="sm"
                      type="button"
                      onMouseDown={handleCloneClick}
                    >
                      <Duplicate color="secondary.500" />
                      Klonovat
                    </Button>
                  )
                }
                rightButtons={
                  !viewOnly ? (
                    <>
                      {canNextWithoutSave ? (
                        <Button
                          colorScheme="primary"
                          type="submit"
                          onClick={() => navigate(`/sme/wizard/edit/${id}/2`)}
                        >
                          Pokračovat
                        </Button>
                      ) : (
                        <Button
                          colorScheme="primary"
                          disabled={isLoading || patchIsLoading}
                          isLoading={isLoading || patchIsLoading}
                          loadingText="Ukládám..."
                          type="submit"
                          onClick={() => {
                            validateAndScroll(values, validateForm, toastMessage)
                            setValidatesCount(validatesCount + 1);
                          }}
                        >
                          <Check />
                          Uložit a pokračovat
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      colorScheme="primary"
                      onClick={() => navigate(`/sme/wizard/view/${id}/2`)}
                    >
                      Pokračovat
                    </Button>
                  )
                }
              />
            </MainContent>
          </Form>
        )
      }}
    </Formik>
  )
}
