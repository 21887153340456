import * as Yup from 'yup'
import { TestContext } from 'yup'
import { IS_NUMBER, IS_REQUIRED } from '../constants/validationMessages'
import { InsuranceTypeEnum } from '../enums/InsuranceTypeEnum'
import { InsureeTypeEnum } from '../enums/InsureeTypeEnum'

function validateForInsuranceTypeOnly(
  self: TestContext | any,
  fieldValue: any,
  validator: (val: any) => boolean,
  type: InsuranceTypeEnum,
): boolean {
  const data: any = self.options?.from[1]?.value

  const insuranceType: Array<string> = data?.insurance.type || []
  const isRequired = insuranceType.includes(type)

  if (!isRequired) {
    return true
  }

  return validator(fieldValue)
}

export const ApplicantSchema = Yup.object({
  type: Yup.string().required(IS_REQUIRED),
  ino: Yup.string().required(IS_REQUIRED).typeError(IS_REQUIRED),
  companyName: Yup.string().required(IS_REQUIRED),
  addressSameAsPlace: Yup.boolean().optional(),
  hasBigRisk: Yup.boolean().optional(),
  ruianId: Yup.number().when('addressSameAsPlace', {
    is: true,
    then: Yup.number().required(IS_REQUIRED).typeError(IS_REQUIRED),
    otherwise: (schema) => schema.nullable(),
  }),
  street: Yup.string().when('addressSameAsPlace', {
    is: false,
    then: Yup.string().optional().typeError(IS_REQUIRED),
    otherwise: (schema) => schema.nullable(),
  }),
  streetNumber: Yup.string().when('addressSameAsPlace', {
    is: false,
    then: Yup.string().optional().typeError(IS_REQUIRED),
    otherwise: (schema) => schema.nullable(),
  }),
  city: Yup.string().when('addressSameAsPlace', {
    is: false,
    then: Yup.string().required(IS_REQUIRED).typeError(IS_REQUIRED),
    otherwise: (schema) => schema.nullable(),
  }),
  zip: Yup.string().when('addressSameAsPlace', {
    is: false,
    then: Yup.string().required(IS_REQUIRED).typeError(IS_REQUIRED),
    otherwise: (schema) => schema.nullable(),
  }),
})

export const ApplicantSchemaWithDetails = Yup.object({
  ...ApplicantSchema.fields,
  identificationNumber: Yup.string().when('type', {
    is: (type: string) => type === InsureeTypeEnum.PERSON,
    then: Yup.string().required(IS_REQUIRED).typeError(IS_REQUIRED),
    otherwise: (schema) => schema.nullable(),
  }),
  firstName: Yup.string().required(IS_REQUIRED).typeError(IS_REQUIRED),
  familyName: Yup.string().required(IS_REQUIRED).typeError(IS_REQUIRED),
  mobilePhone: Yup.string().required(IS_REQUIRED).nullable(),
  email: Yup.string().required(IS_REQUIRED).nullable(),
  entryFromRegister: Yup.string().required(IS_REQUIRED),
})

export const Step1FormSchema = Yup.object({
  business: Yup.object({
    profession: Yup.number().required(IS_REQUIRED).min(1, 'Je potřeba vybrat'),
    field: Yup.number().required(IS_REQUIRED).min(1, 'Je potřeba vybrat'),
  }),
  insurance: Yup.object({
    name: Yup.string().optional(),
    type: Yup.array().min(1, IS_REQUIRED),
    range: Yup.string().required(IS_REQUIRED),
    dateStart: Yup.string().required(IS_REQUIRED),
    paymentFrequency: Yup.string().required(IS_REQUIRED),
    paymentMethod: Yup.string().required(IS_REQUIRED),
    companies: Yup.array().min(1, IS_REQUIRED),
  }),
  places: Yup.array().of(
    Yup.object({
      street: Yup.string().required(IS_REQUIRED),
      streetNumber: Yup.string().required(IS_REQUIRED),
      zip: Yup.string().required(IS_REQUIRED),
      city: Yup.string().required(IS_REQUIRED),
      constructionType: Yup.mixed().test(
        'constructionTypeTest',
        IS_REQUIRED,
        function (this: TestContext | any, fieldValue: any): boolean {
          return validateForInsuranceTypeOnly(
            this,
            fieldValue,
            (fieldValue) => !!fieldValue && typeof fieldValue === 'string' && fieldValue.length > 0,
            InsuranceTypeEnum.BUILDING,
          )
        },
      ),
      floorQuantity: Yup.mixed().test(
        'floorQuantityTest',
        IS_REQUIRED,
        function (this: TestContext | any, fieldValue: any): boolean {
          return validateForInsuranceTypeOnly(
            this,
            fieldValue,
            (fieldValue) => typeof fieldValue !== 'undefined',
            InsuranceTypeEnum.BUILDING,
          )
        },
      ),
      area: Yup.mixed().test(
        'areaTest',
        IS_REQUIRED,
        function (this: TestContext | any, fieldValue: any): boolean {
          return validateForInsuranceTypeOnly(
            this,
            fieldValue,
            (fieldValue) => typeof fieldValue === 'number',
            InsuranceTypeEnum.BUILDING,
          )
        },
      ),
      buildingPrice: Yup.mixed().test(
        'buildingPriceTest',
        IS_REQUIRED,
        function (this: TestContext | any, fieldValue: any): boolean {
          return validateForInsuranceTypeOnly(
            this,
            fieldValue,
            (fieldValue) => typeof fieldValue === 'number',
            InsuranceTypeEnum.BUILDING,
          )
        },
      ),
      technicalState: Yup.mixed().test(
        'technicalStateTest',
        IS_REQUIRED,
        function (this: TestContext | any, fieldValue: any): boolean {
          return validateForInsuranceTypeOnly(
            this,
            fieldValue,
            (fieldValue) => !!fieldValue && typeof fieldValue === 'string' && fieldValue.length > 0,
            InsuranceTypeEnum.BUILDING,
          )
        },
      ),
      ruianId: Yup.number().optional(),
    }),
  ),
  additional: Yup.object({
    supplyValue: Yup.number().test(
      'supplyValueTest',
      IS_REQUIRED,
      function (this: TestContext | any, fieldValue: any): boolean {
        return validateForInsuranceTypeOnly(
          this,
          fieldValue,
          (fieldValue) => typeof fieldValue === 'number' && fieldValue > -1,
          InsuranceTypeEnum.MACHINE,
        )
      },
    ),
    machineValue: Yup.number().typeError(IS_NUMBER).required(IS_REQUIRED).min(0, IS_REQUIRED),
    totalReceipt: Yup.number().typeError(IS_NUMBER).required(IS_REQUIRED).min(0, IS_REQUIRED),
    numberEmployee: Yup.number().typeError(IS_NUMBER).required(IS_REQUIRED).min(0, IS_REQUIRED),
    liabilityLimit: Yup.mixed().test(
      'liabilityLimitTest',
      IS_REQUIRED,
      function (this: TestContext | any, fieldValue: any): boolean {
        return validateForInsuranceTypeOnly(
          this,
          fieldValue,
          (fieldValue) => !isNaN(fieldValue) && fieldValue > 0,
          InsuranceTypeEnum.RESPONSIBILITY,
        )
      },
    ),
    region: Yup.mixed().test(
      'regionTest',
      IS_REQUIRED,
      function (this: TestContext | any, fieldValue: any): boolean {
        return validateForInsuranceTypeOnly(
          this,
          fieldValue,
          (fieldValue) => !!fieldValue && typeof fieldValue === 'string' && fieldValue.length > 0,
          InsuranceTypeEnum.RESPONSIBILITY,
        )
      },
    ),
  }),
  applicant: ApplicantSchema,
})
