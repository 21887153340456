import queryString from 'query-string'
import api from '../api'
import { useQuery } from '@tanstack/react-query'
import { ApiRouteEnum } from '../../enums/ApiRouteEnum'
import { IUserDegrees } from '../../interfaces/entities/IUser'

export const useGetUserDegrees = (params?: any) => {
  const query = queryString.stringify(params, { skipEmptyString: true })
  const degrees: string = `${ApiRouteEnum.USER_DEGREES}?${query}`
  return useQuery([ApiRouteEnum.USER_DEGREES, query], () => api.get<IUserDegrees>(degrees), {
    retry: false,
  })
}
