import { ChangeEventHandler, useEffect, useState } from 'react'
import { forwardRef, Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react'
import Flatpickr from 'react-flatpickr'
import { Czech } from 'flatpickr/dist/l10n/cs'
import { BaseOptions } from 'flatpickr/dist/types/options'
import 'flatpickr/dist/themes/light.css'
import "flatpickr/dist/plugins/monthSelect/style.css";

import { Calendar } from '../icons'
import './Datepicker.css'

interface DatepickerProps extends InputProps {
  date: Date | undefined
  onDateChange?: Function
  options?: BaseOptions|any
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined
  onlyMonth?: boolean
}

export const Datepicker = forwardRef((props: DatepickerProps, ref) => {
  const { options, date: originalDate, onlyMonth } = props
  const [date, setDate] = useState(originalDate)

  useEffect(() => {
    if (props.date) {
      setDate(props.date)
    }
  }, [props])

  const handleDateChange = (date: Date) => {
    props.onDateChange && props.onDateChange(date)
    setDate(date)
  }

  const newOptions = () => {
    let _options = {
      ...options,
      dateFormat: 'd.m.Y',
      locale: Czech,
      allowInput: true
    };
    if (onlyMonth) {
      _options.dateFormat = "Y-m"
      _options.plugins = [new (require("flatpickr/dist/plugins/monthSelect"))({})]
    }

    return _options;
  }

  return (
    <Flatpickr
      options={newOptions()}
      value={date}
      render={({ defaultValue, value, ...props }, ref) => {
        return (
          <InputGroup>
            <InputLeftElement pointerEvents="none" children={<Calendar width={4} height={4} />} />

            <Input defaultValue={defaultValue} ref={ref} htmlSize={8} />
          </InputGroup>
        )
      }}
      onChange={([date]) => handleDateChange(date)}
    />
  )
})
