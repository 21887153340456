import {
  IAdditional,
  IApplicant,
  IBusiness,
  InsuranceValues,
  IPlace,
} from '../../data/InsuranceForm'
import moment from 'moment'

export const initialBusinessValues: IBusiness = {
  profession: 0,
  field: 0,
}

export const initialInsuranceValues: InsuranceValues = {
  name: '',
  type: [],
  range: 'low',
  dateStart: moment().add(1, 'days').format('YYYY-MM-DD'),
  agreement: 'payment',
  paymentFrequency: 'year',
  paymentMethod: 'bank_account',
  companies: [],
}

export const initialPlaceValues: IPlace = {
  street: '',
  streetNumber: '',
  zip: '',
  city: '',
  constructionType: '',
  floorQuantity: 0,
  area: 0,
  buildingPrice: 0,
  technicalState: '',
  ruianId: 0,
}

export const initialAdditionalValues: IAdditional = {
  supplyValue: -1,
  machineValue: 0,
  totalReceipt: 0,
  numberEmployee: 0,
  liabilityLimit: 0,
  region: 'cr',
}

export const initialApplicantValues: IApplicant = {
  type: '',
  ino: '',
  identificationNumber: '',
  companyName: '',
  title: '',
  firstName: '',
  familyName: '',
  ruianId: 0,
  street: '',
  streetNumber: '',
  city: '',
  zip: '',
  mobilePhone: '',
  phone: '',
  email: '',
  communicationConsent: true,
  vatPayer: false,
  entryFromRegister: '',
  mandatoryPerson: false,
  addressSameAsPlace: false,
  hasBigRisk: false,
  communicationConsentDuringContractDuration: false,
}
